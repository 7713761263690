    @media only screen and (min-width: 850px) {
        #app .foto-fundo-hero {
            right: 0;
            bottom: -5%;
        }

        #app .hero-texto-container {
            width: 60%;
        }

        #app .hero-title {
            font-size: 3rem;
        }

        #app .hero-subtitle {
            font-size: 1.3rem;
        }

        #app .hero-uptitle {
            font-size: 1.9rem;
            line-height: 2rem;
        }

        #app .beneficios-wrap {
            width: 60%;
            flex-grow: 1;
        }

        #app .motivo-icon svg {
            width: 120px;
            height: 120px;
            margin-left: -9%;

        }

        #app .cta--wrap .logo-fundo {
            right: 56px;
            max-height: 372px;
            bottom: 0px;
            top: unset;
        }

        #app .logo-fundo {
            height: 150%;
        }
    }